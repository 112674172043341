import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import BkashCreatePaymentAPICall from "../../action/BkashCreatePaymentAPICall";
import "./CheckOut.css";
import AnimatedInput from "../AnimatedInput/AnimatedInput";
import AnimatedInput1 from "../AnimatedInput/AnimatedInput1";
import AnimatedInput2 from "../AnimatedInput/AnimatedInput2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DuplicateOrder from "../../action/DuplicateOrder";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import { FiCheckSquare } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import myAxios from "../AxiosHeader";
import TagManager from "react-gtm-module";
import Bkash from "../../image/Bkash.png";
import Nagad from "../../image/Nagad.png";
import Rocket from "../../image/Rocket.png";
import COD from "../../image/COD.png";
import CreditCard from "../../image/Card.png";

function CheckOut() {
  const [store, setStore] = useState();
  const [cName, setName] = useState("");
  const [cMobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [Number, setNumber] = useState();
  const [DeliveryDhaka, setDeliveryDhaka] = useState();
  const [DeliveryOut, setDeliveryOut] = useState();
  const [privateNote, setPrivateNote] = useState("");
  const [SelectPayment, setSelectPayment] = useState("CASH ON DELIVERY");
  const navigate = useNavigate();

  // const orderUrl = "http://localhost:5000";
  const orderUrl = "https://order.ecomtechbd.com";
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;
  const total = state.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  DuplicateOrder(state);
  let Sfrom = 1;
  const ShipFrom = state.map((data) => {
    if (data.ShipFrom === "dhaka" || data.ShipFrom === "Dhaka") {
      return (Sfrom = 0);
    }
  });

  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        setStore(res.data);
        setNumber(res.data.number);
        setDeliveryDhaka(res.data.Dhaka);
        setDeliveryOut(res.data.Outside);
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  let orderData = state;
  const item = state.length ? state.length : 0;

  let Product_Code = [];
  state.map((data) => {
    var pData = {};
    pData["pCode"] = data.pCode;
    pData["color"] = data.colorName;
    pData["Quality"] = data.quantity;
    pData["Size"] = data.Size ? data.Size : "";
    Product_Code.push(pData);
  });

  const SubmitOrder = () => {
    setLoading(true);
    if (!state.length) {
      navigate("/");
    }

    if (!cName || !address) {
      toast.error("নাম ঠিকানা লিখুন");
      setLoading(false);
      return;
    }

    if (cMobile.length < 11 || cMobile.length > 11) {
      toast.error("১১ সংখ্যার মোবাইল নম্বর লিখুন");
      setLoading(false);
      return;
    }

    if (!location) {
      toast.error("ঢাকা সিটিতে না বাহিরে ? সিলেক্ট করুন ");
      setLoading(false);
      return;
    }

    const formData = {
      cName,
      cMobile,
      address,
      deliveryCharge: location,
      totalPrice: total,
      item,
      orderData,
      product: Product_Code,
      privateNote,
      Overseas: Sfrom,
      PaymentType: SelectPayment,
      TrafficSource: localStorage.getItem("TrafficSource"),
    };

    axios
      .post(`${orderUrl}`, formData)
      .then((res) => {
        PixelEvent();
        let Invoice = res.data.invoiceId;
        let OrderId = res.data.id;
        if (res.data === "error") {
          toast.error(
            `অর্ডার করতে সমস্যা হয়েছে, এই নাম্বার এ কল দিন ${store.number} `
          );
          dispatch({ type: "RESET", payload: [] });
          setLoading(false);
        } else {
          dispatch({ type: "RESET", payload: [] });
          setLoading(false);
          if (SelectPayment == "BKASH") {
            BkashCreatePaymentAPICall(total, Invoice, OrderId);
          } else {
            navigate("/thanks");
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let is_running = false;
  const PixelEvent = async () => {
    if (is_running == true) {
      return;
    }
    is_running = true;
    ReactPixel.track("Purchase", { currency: "BDT", value: total });
    is_running = false;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {total
            ? `Total ৳ ${total} Plus Delivery Charge CheckOut`
            : "Thank you for your order"}
        </title>
      </Helmet>

      <div className="mainCheckout">
        <ToastContainer />
        <div className="checkout-container">
          <div className="checkout-left">
            {/* {!hidenNumber && (
            <div className="mobile-section">
              <p>আপনার অর্ডার টি সম্পন্ন করতে মোবাইল নাম্বার দিন</p>
              <div className="mobile-number-checkout">
                <input
                  type="number"
                  className="mobile-checkout"
                  name="mobile"
                  placeholder="মোবাইল নাম্বার"
                />
              </div>
              <button
                className="call-button-checkout"
                onClick={() => setHidenNumber(true)}
              >
                অর্ডার করুন
              </button>
            </div>
          )} */}

            <div className="mobile-section">
              <p>
                অর্ডার টি সম্পন্ন করতে আপনার নাম, মোবাইল নাম্বার ও ঠিকানা নিচে
                লিখুন
              </p>
              <div className="mobile-number-checkout">
                <AnimatedInput
                  type="text"
                  className="mobile-checkout"
                  name="name"
                  placeholder="এখানে আপনার নাম লিখুন"
                  value={cName}
                  onChange={(e) => setName(e.target.value)}
                />
                <AnimatedInput
                  type="number"
                  className="mobile-checkout"
                  name="mobile"
                  placeholder="১১ সংখ্যার মোবাইল নম্বর লিখুন"
                  value={cMobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                <AnimatedInput1
                  className="mobile-checkout"
                  type="text"
                  name="address"
                  placeholder="আপনার ঠিকানা টি এখানে লিখুন"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="deliveryLocation">
                <FormControl onChange={(e) => setLocation(e.target.value)}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="dhaka"
                      control={<Radio />}
                      label={`ঢাকা সিটিতে ৳${DeliveryDhaka}`}
                      style={{ margin: "0px" }}
                    />
                    <FormControlLabel
                      value="outside"
                      control={<Radio />}
                      label={`সিটির বাহিরে ৳${DeliveryOut}`}
                      style={{ margin: "0px" }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="Payment">
                <div className="Payment-Message">Get 10% Discount on Bkash</div>
                <div className="Payment-Getway">
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("CASH ON DELIVERY")}
                  >
                    <img
                      className={
                        SelectPayment == "CASH ON DELIVERY"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={COD}
                      alt="CASH ON DELIVERY"
                    />
                  </div>
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("BKASH")}
                  >
                    <img
                      className={
                        SelectPayment == "BKASH"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={Bkash}
                      alt="BKASH"
                    />
                  </div>
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("NAGAD")}
                  >
                    <img
                      className={
                        SelectPayment == "NAGAD"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={Nagad}
                      alt="NAGAD"
                    />
                  </div>
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("ROCKET")}
                  >
                    <img
                      className={
                        SelectPayment == "ROCKET"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={Rocket}
                      alt="ROCKET"
                    />
                  </div>
                  {/* <div className="Getway-Option" onClick={()=> setSelectPayment("CREDIT CARD")}>
                <img src={CreditCard} alt="CREDIT CARD"/>
                </div> */}
                </div>
                <div className="Payment-Message">
                  <h5>
                    {SelectPayment} : সিলেক্ট করা আছে অর্ডার বাটন এ ক্লিক করুন
                  </h5>
                </div>
              </div>
              <button className="call-button-checkout" onClick={SubmitOrder}>
                {loading ? "Loading.." : "অর্ডার করুন"}
              </button>
              <AnimatedInput2
                className="privateNote"
                type="text"
                name="note"
                placeholder="অর্ডার নোট থাকলে এখানে লিখুন"
                value={privateNote}
                onChange={(e) => setPrivateNote(e.target.value)}
              />
              <p>
                আমরা সারাদেশে হোম ডেলিভারি করেথাকি, ঢাকাতে {DeliveryDhaka} টাকা
                এবং ঢাকার বাহিরে {DeliveryOut} টাকা ডেলিভারি চার্জ, আপনার টোটাল
                টাকার সাথে ডেলিভারি চার্জ টা অ্যাড হবে ধন্নবাদ{" "}
              </p>
            </div>
          </div>

          <div className="checkout-right">
            <div className="card-data-section">
              <div className="Total-Cart">
                <div className="total-item-">Total ৳ {total}</div>
                <div className="total-item-">Cart Item {state.length}</div>
              </div>
              <div className="checkout-head">
                <div>Photo</div>
                <div>Color</div>
                <div>Size</div>
                <div>Taka</div>
                <div>Quantity</div>
                <div>Remove</div>
              </div>
              {state.length <= 0 && <div className="no-cart-data">No data</div>}
              {state?.map((data, index) => {
                return (
                  <div className="cart-item" key={index}>
                    <img src={data.image} alt={data.colorName} />
                    <h2>{data.colorName}</h2>
                    <h2>{data.Size ? data.Size : "null"}</h2>
                    <h2>৳ {data.price}</h2>
                    <div className="cart-input-container">
                      <button
                        className="cart-button"
                        onClick={() => {
                          if (data.quantity > 1) {
                            dispatch({ type: "DECREASE", payload: data });
                          } else {
                            return;
                          }
                        }}
                      >
                        -
                      </button>
                      <input
                        className="cart-input"
                        type="text"
                        name="cartinput"
                        value={data.quantity}
                      />
                      <button
                        className="cart-button"
                        onClick={() => {
                          if (data.quantity >= data.Available) {
                            toast.error("Quantity limit");
                          } else {
                            dispatch({ type: "INCREASE", payload: data });
                          }
                        }}
                      >
                        +
                      </button>
                    </div>
                    <button
                      className="cart-remove"
                      onClick={() => {
                        dispatch({ type: "REMOVE", payload: data });
                      }}
                    >
                      Remove
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckOut;
