import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Category.css";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import ReactLoading from "react-loading";
import { motion } from "framer-motion";

function Category() {
  const [category, setCategory] = useState([]);
  const [nocategory, setNocategory] = useState(false);
  const [loadCategory, setLoadCategory] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${URL}/e-category`)
      .then((res) => {
        setCategory(res.data);
        setLoadCategory(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (category.length === 0 && !loadCategory) {
      setNocategory(true);
    }
  }, [loadCategory]);

  return (
    <>
      {loadCategory && (
        <div className="category-loading">
          <div className="category-loading-in">
            <ReactLoading
              type="spin"
              color="rgb(226,115,29)"
              height={60}
              width={60}
            />
          </div>
        </div>
      )}

      {nocategory && <div className="no-category">No Category Found..</div>}
      <div className="category-main">
        <motion.div
          className="category-wrap"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.2 }}
        >
          {category &&
            category.map((item, index) => {
              return (
                <div
                  className="category-box-h3"
                  key={index}
                  onClick={() => navigate(`/view-category/${item.id}`)}
                >
                  <div className="category-box">
                    <img src={item.imgUrl} alt="category" />
                  </div>
                  <h3>{item.name}</h3>
                </div>
              );
            })}
        </motion.div>
      </div>
    </>
  );
}

export default Category;
